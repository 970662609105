// @flow

import React, { Fragment } from 'react';
import type { Node } from 'react';
import { useIntl } from 'react-intl';
import { useSelector, shallowEqual } from 'react-redux';
import VisibilitySensor from 'react-visibility-sensor';
import { ArtCard } from '@riseart/art';
import { ContinuousSlider } from '@riseart/slider';
import { isTouchDevice } from '@riseart/fe-utils';
import { LazyloadPlaceholder, MediaQuery } from '@riseart/common';
import { useLocale } from 'shared_services/redux/hooks/useLocale';
import { selectUnitSystem } from 'shared_services/redux/selectors/unitSystem';
import { extractImageFileFromData } from 'shared_services/riseart/utils/ImageUtils';
import { FavoritesToggleMutation } from 'shared_data/providers/queries/FavoritesToggleMutation';
import { dataToListProps } from 'shared_services/riseart/utils/artDataUtils';
import { useListTracking } from 'shared_services/redux/hooks/useListTracking';

import {
  fixedHeightSliderMinMediaWidth,
  fixedHeightSliderCls,
} from 'shared_components/arts/slider/fixedHeight/Slider.less';
import { raScreenSm, raScreenLg } from '@riseart/antd-provider/dist/website/variables.less';

type SlideConfigType = { sm: number, md: number, lg: number };

type Props = {
  items: Array<Object>,
  height?: number,
  cardDisplayStyle?: number | string,
  artCardProps?: Object,
  artDirectionSizesKey?: string,
  actionControls?: Function,
  hideActionControls?: boolean,
  draggable?: boolean,
  slideHeightConfig?: SlideConfigType,
  tracking?: Object,
  lazyloadAfter?: number,
  disableHeadingTags?: boolean,
};

/**
 * getPlaceholderDimensions
 *
 * @param {boolean} isTablet
 * @param {boolean} isDesktop
 * @param {Object} { width, height }
 */
function getPlaceholderDimensions(
  isTablet: boolean,
  isDesktop: boolean,
  config: SlideConfigType,
  { width, height }: Object = {},
) {
  let fixedHeight = config.sm;

  if (isDesktop) {
    fixedHeight = config.lg;
  } else if (isTablet) {
    fixedHeight = config.md;
  }

  return {
    height: fixedHeight,
    width: (fixedHeight / height) * width,
  };
}

/**
 * FixedHeightSlider
 */
export const FixedHeightSlider = ({
  items,
  height = 450,
  cardDisplayStyle = 2,
  artCardProps,
  artDirectionSizesKey = 'slider.gridFixedHeight450',
  actionControls,
  hideActionControls,
  draggable = isTouchDevice(),
  slideHeightConfig = { sm: 300, md: 360, lg: 450 },
  tracking,
  lazyloadAfter,
  disableHeadingTags = false,
}: Props): Node => {
  const LAZYLOAD_OFFSET_PX = 200;
  const { formatMessage } = useIntl();
  const locale = useLocale();
  const { unitSystem } = useSelector(selectUnitSystem, shallowEqual);
  const { onArtworkClick, ...restArtCardProps } = artCardProps || {};
  const { handleItemClick, handleVisibilityChange } = useListTracking(
    items,
    tracking,
    onArtworkClick,
  );
  const requiresVisibilityTracking =
    tracking && tracking.enabled && tracking.enableVisibility && !tracking.enableInitial;
  const VisibilityComponent = requiresVisibilityTracking ? VisibilitySensor : Fragment;
  const visibilityComponentProps = requiresVisibilityTracking
    ? {
        onChange: handleVisibilityChange,
        partialVisibility: true,
        ...((tracking && tracking.visibilityProps) || null),
      }
    : {};

  return (
    // $FlowFixMe
    <VisibilityComponent {...visibilityComponentProps}>
      <MediaQuery minWidth={raScreenSm}>
        {(isTablet) => (
          <MediaQuery minWidth={raScreenLg}>
            {(isDesktop) => (
              <ContinuousSlider
                className={fixedHeightSliderCls}
                draggable={draggable}
                height={height}
                nextArrowText={formatMessage({ id: 'common.next' })}
                previousArrowText={formatMessage({ id: 'common.previous' })}
              >
                {items.map((i, idx) => {
                  const artImage =
                    extractImageFileFromData.artImage(i.images, 'TYPE_MAIN_MASTER', true) ||
                    extractImageFileFromData.artImage(i.images, 'TYPE_FLAT_MASTER');
                  const {
                    id,
                    title,
                    price,
                    artworkUrl,
                    artistName,
                    artistUrl,
                    additionalContent,
                    pictureProperties,
                  } = dataToListProps(
                    { ...i, artImage },
                    locale,
                    artDirectionSizesKey,
                    formatMessage,
                    'fixedHeight',
                    unitSystem,
                  );
                  const alt = formatMessage(
                    { id: 'components.art.artwork_by_artist' },
                    { title, artist: artistName },
                  );
                  let actionComponents = null;

                  if (!hideActionControls) {
                    actionComponents =
                      typeof actionControls === 'function' ? (
                        actionControls(i)
                      ) : (
                        <FavoritesToggleMutation artId={id} />
                      );
                  }

                  const placeholderProps = getPlaceholderDimensions(
                    isTablet,
                    isDesktop,
                    slideHeightConfig,
                    artImage,
                  );
                  return (
                    <ArtCard
                      key={id}
                      displayStyle={cardDisplayStyle}
                      artistName={artistName}
                      artistUrl={artistUrl}
                      linkProps={{ title: alt }}
                      pictureProps={{
                        ...pictureProperties,
                        alt,
                        lazyload:
                          !lazyloadAfter || lazyloadAfter <= idx
                            ? {
                                once: true,
                                offset: LAZYLOAD_OFFSET_PX,
                                placeholder: <LazyloadPlaceholder {...placeholderProps} />,
                              }
                            : null,
                      }}
                      cardContentProps={
                        placeholderProps && placeholderProps.width
                          ? {
                              style: {
                                maxWidth: Math.max(
                                  parseInt(fixedHeightSliderMinMediaWidth, 10),
                                  Math.floor(placeholderProps.width),
                                ),
                              },
                            }
                          : null
                      }
                      artworkUrl={artworkUrl}
                      artworkTitle={title}
                      price={price}
                      additionalContent={additionalContent}
                      actionControls={actionComponents}
                      {...restArtCardProps}
                      onArtworkClick={handleItemClick(i)}
                      disableHeadingTags={disableHeadingTags}
                    />
                  );
                })}
              </ContinuousSlider>
            )}
          </MediaQuery>
        )}
      </MediaQuery>
    </VisibilityComponent>
  );
};
